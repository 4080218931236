import request from 'utils/request';
const api = {
  LIST_FINANCES: '/api/finance/overdue/list',
  UPDATE_DELAY_REASON: '/api/finance/overdue/updateDelayReason',
};

/**
 * 获取 Finance 对账流水（分页）
 * @param params
 * @returns {*}
 */
export function fetchPage(params) {
  return request({
    url: api.LIST_FINANCES,
    method: 'get',
    params,
  });
}

export function updateDelayReason(data) {
  return request({
    url: api.UPDATE_DELAY_REASON,
    method: 'post',
    data,
  });
}
