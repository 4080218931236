<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row
            :gutter="10"
            type="flex"
            justify="start"
            style="flex-wrap: wrap; flex-direction: row"
          >
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="period:" class="mb5">
                <el-date-picker
                  v-model="filterForm.dateRange"
                  type="monthrange"
                  value-format="yyyy-MM"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="source：" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filterForm.sourceIdList"
                      :data-source="sourcesList"
                      :multiple="true"
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      collapse-tags
                      placeholder="select Source"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterForm.showBusiness"
                      @change="getList(1)"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="contracts:" class="mb5">
                <el-select
                  v-model="filterForm.contracts"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option v-for="item in contracts" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="legalAdv:" class="mb5">
                <el-select
                  v-model="filterForm.advertisersLegalName"
                  filterable
                  collapse-tags
                  placeholder="Please select"
                  class="w100"
                  clearable
                >
                  <el-option
                    v-for="item in advLegalNameList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="receive(s):" class="mb5">
                <el-select
                  v-model="filterForm.receiveStatusList"
                  :multiple="true"
                  clearable
                  style="width: auto"
                >
                  <el-option
                    v-for="item in receiveStatusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="Delay:" class="mb5">
                <el-select v-model="filterForm.delay" clearable style="width: auto">
                  <el-option
                    v-for="item in receiveStatusList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="BD:" class="mb5">
                <group-select
                  v-model="filterForm.businessList"
                  :data-source="busineies"
                  :multiple="true"
                  :loading="sourceLoading"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select BD"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="BD Group:" class="mb5">
                <el-select v-model="filterForm.businessType" clearable filterable>
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="AM" class="mb5">
                <group-select
                  v-model="filterForm.accountManage"
                  :data-source="accounts"
                  reserve-keyword
                  clearable
                  filterable
                  collapse-tags
                  placeholder="select AM"
                  class="w100"
                />
              </el-form-item>
            </el-col>
            <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
              <el-form-item label="AM Group" class="mb5">
                <el-select v-model="filterForm.accountType" clearable class="w100">
                  <el-option
                    v-for="item in businessTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="createBy" class="mb5">
                <el-select
                  v-model="filterForm.createBy"
                  filterable
                  clearable
                  placeholder="Please select"
                  class="w100"
                >
                  <el-option
                    v-for="item in option.manager"
                    :key="item.username"
                    :label="item.username"
                    :value="item.username"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Invoice Raising Date:" class="mb5" label-width="160px">
                <el-date-picker
                  v-model="filterForm.raisingRange"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
              <el-form-item label="Date of Received:" class="mb5" label-width="160px">
                <el-date-picker
                  v-model="filterForm.receiveRange"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :xs="5" :sm="5" :md="5" :lg="5" :xl="5">
              <el-form-item label="updateTime:" class="mb5">
                <el-date-picker
                  v-model="filterForm.updateRange"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="to"
                  start-placeholder="start"
                  end-placeholder="end"
                  size="mini"
                  :style="{ width: '100%' }"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0" class="mb5">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="info" :loading="loading.list" @click="downloadFie"
                  >download</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width: 100%"
          height="780"
          stripe
          border
          :data="list"
          tooltip-effect="dark"
          aria-setsize="mini"
          size="mini"
          class="mb10"
          @sort-change="sortChange"
          :row-style="{ height: '50px' }"
          row-key="id"
        >
          <el-table-column
            label="Contract"
            prop="contract"
            align="center"
            :key="10"
          ></el-table-column>
          <el-table-column
            v-if="filterForm.showBusiness"
            label="SourceId"
            prop="sourceId"
            align="center"
            :key="20"
          ></el-table-column>
          <el-table-column
            v-if="filterForm.showBusiness"
            label="SourceName"
            prop="sourceName"
            align="center"
            :key="30"
          ></el-table-column>
          <el-table-column
            label="Advertiser Legal name"
            prop="advertisersLegalName"
            align="center"
            :key="40"
          ></el-table-column>
          <el-table-column label="BD" prop="business" align="center" :key="50"></el-table-column>
          <el-table-column
            label="BD Group"
            prop="businessType"
            align="center"
            :key="60"
          ></el-table-column>
          <el-table-column
            label="AM"
            prop="accountManage"
            align="center"
            :key="70"
          ></el-table-column>
          <el-table-column
            label="AM Group"
            prop="accountType"
            align="center"
            :key="80"
          ></el-table-column>
          <el-table-column
            label="createBy"
            prop="createBy"
            align="center"
            :key="90"
          ></el-table-column>
          <el-table-column label="Invoce No" prop="invoice" align="center" :key="100">
            <template slot-scope="scope">
              <span @click="openFile(scope.row.fileUrl)" class="cor337ab7">{{
                scope.row.invoice
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Status of Received"
            align="center"
            prop="received"
            :key="110"
          ></el-table-column>
          <el-table-column label="Date of Received" prop="receivedDate" align="center" :key="120">
            <template slot-scope="scope">
              {{ formatDate(scope.row.receivedDate) }}
            </template>
          </el-table-column>
          <el-table-column label="Period" prop="financeCycle" align="center" :key="130">
            <template slot-scope="scope">
              <span style="font-weight: bold;">{{ scope.row.financeCycle }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Billing Revenue"
            prop="amount"
            align="center"
            sortable="custom"
            :key="140"
          >
            <template slot-scope="scope">
              {{ scope.row.amount | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column
            label="Actual Revenue received"
            prop="totalActualReceive"
            align="center"
            sortable="custom"
            :key="150"
          >
            <template slot-scope="scope">
              {{ scope.row.totalActualReceive | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Unreceived" prop="unreceived" align="center" :key="160">
            <template slot-scope="scope">
              {{ scope.row.unreceived | numberToCurrency }}
            </template>
          </el-table-column>
          <el-table-column label="Date of Receipt" prop="receiptDay" align="center" :key="170">
            <template slot-scope="scope">
              {{ formatDate(scope.row.receiptDay) }}
            </template>
          </el-table-column>
          <el-table-column label="Delay" prop="delay" align="center" :key="180"></el-table-column>
          <el-table-column
            label="Delay Period"
            prop="delayPeriod"
            align="center"
            :key="190"
          ></el-table-column>
          <el-table-column
            label="POP SCREENSHOTS"
            prop="popScreenshots"
            align="center"
            show-overflow-tooltip
            :key="200"
          >
            <template slot-scope="scope">
              <span @click="openFile(scope.row.popScreenshots)" class="cor337ab7">{{
                scope.row.popScreenshots
              }}</span>
              <el-upload
                class="upload-demo"
                action="/api/finance/overdue/uploadFile"
                v-if="scope.row.id"
                :before-upload="(file) => beforeUpload(file, scope.row)"
                :on-success="handleUploadSuccess"
                :auto-upload="true"
                :data="{ id: scope.row.id }"
                :show-file-list="false"
              >
                <i title="upload POP SCREENSHOTS" class="el-icon-upload"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column
            label="Reason for Delay"
            prop="delayReason"
            align="center"
            show-overflow-tooltip
            :key="210"
          >
            <template slot-scope="scope">
              {{ scope.row.delayReason }}
              <br />
              <i
                v-if="scope.row.id"
                class="el-icon-edit"
                @click="updateDelayReason(scope.row.id, scope.row.delayReason)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            label="Invoice Raising Date"
            prop="invoiceRaisingDate"
            align="center"
            :key="220"
          >
            <template slot-scope="scope">
              {{ formatDate(scope.row.invoiceRaisingDate) }}
            </template>
          </el-table-column>
          <el-table-column
            label="createTime"
            prop="createTime"
            align="center"
            :key="230"
          ></el-table-column>
          <el-table-column
            label="updateBy"
            prop="updateBy"
            align="center"
            :key="240"
          ></el-table-column>
          <el-table-column
            label="updateTime"
            prop="updateTime"
            align="center"
            :key="250"
          ></el-table-column>
        </el-table>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import finance from '../controllers/finance/financeOverdue';
  import GroupSelect from '@/components/GroupSelect/index.vue';
  export default {
    name: 'finance',
    components: { GroupSelect },
    ...finance,
  };
</script>

<style scoped>
  .table-row {
    height: 50px;
  }
  .row {
    display: flex;
  }
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
